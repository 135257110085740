.table {
    td {
        border-top: none;
        border-bottom: 1px solid #EBEDF3;
    }

    &.table-bmw {
        .form-control {
            padding: 0.65rem 0.2rem;
        }

        thead {
            th {
                position: relative;

                &:not(.expand-cell-header) {
                    .svg-icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }

                .form-control {
                    font-size: 0.9rem;
                    font-style: normal;
                }

                .date-filter-comparator {
                    width: 43px;
                }

                .date-filter-input {
                    max-width: 130px;
                    width: auto;

                    &::-webkit-calendar-picker-indicator {
                        margin-inline-start: 0;
                    }
                }

                .number-filter {
                    .number-filter-comparator {
                        width: 45px;
                    }

                    .number-filter-input {
                        width: calc(100% - 5px);
                    }
                }
            }

            tr, th {
                vertical-align: top;

            }
        }
    }
}
