@import '~react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

@import "components/dialog";
@import "components/accordion";
@import "components/buttons";
@import "components/card";
@import "components/dropzone";
@import "components/table";
@import "components/aside";
@import "components/wizard";
@import "components/brands";
@import "components/chart";
@import "components/logo";
@import "components/document";

@import "vendors/plugins/react-bootstrap-table2";

//#4d4d4d
//background-color: #1c69d4;
//border-color: #0653b6;
$breakpoints: $grid-breakpoints;
$columns: $grid-columns;
.form-group {
    //.col{
    //    margin-bottom: 5px;
    //}

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @if $columns > 0 {
            // Allow columns to stretch full width below their breakpoints
            @for $i from 1 through $columns {
                .col#{$infix}-#{$i} {
                    margin-bottom: 1.75rem;
                }
            }
        }
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

