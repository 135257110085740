$brands: (
        bmw: #1C69D4,
        bmwi: #1C69D4,
        mini: #CC0000,
        moto: #16171A,
);

$brandsLight: (
        bmw: #F3F6F9,
        bmwi: #F3F6F9,
        mini: #F9EDED,
        moto: #EFEFEF,
);

$brandsHover: (
        bmw: #0653B6,
        bmwi: #0653B6,
        mini: darken(#CC0000, 5%),
        moto: darken(#16171A, 5%),
);

@each $brand in map-keys($brands) {
    $color: map-get($brands, $brand);
    $colorLight: map-get($brandsLight, $brand);
    $colorHover: map-get($brandsHover, $brand);
    .bg-#{$brand} {
        background-color: $color !important;
    }
    .btn-#{$brand} {
        background-color: $color;
        border-color: $color;
        color: #FFFFFF;

        &:hover {
            background-color: $colorHover;
            border-color: $colorHover;
            color: #FFFFFF;
        }
    }
    .text-#{$brand} {
        color: $color;
    }
    //.navi.navi-hover .navi-item .navi-link:hover
    .navi-#{$brand} {
        &.navi.navi-hover {
            .navi-item {
                .navi-link {
                    &:hover {
                        background-color: $colorLight;
                    }
                }
            }
        }

        .navi-item {
            .navi-link {


                &:hover {
                    color: $color;
                    background-color: $colorLight;

                    .navi-text {
                        color: $color;
                    }
                }
            }
        }
    }

    .dropdown-#{$brand} {
        .dropdown-item.disabled {
            color: $color;
        }

        .dropdown-item.dropdown-item-enabled {
            &:hover {
                color: $color;
                background-color: $colorLight;
            }
        }
    }

}
