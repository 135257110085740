//background-color: #1c69d4;
//border-color: #0653b6;
$wizardBackgroundColor: #1c69d4;
$wizardTextColor: #FFFFFF;

.wizard.wizard-6 {
    .wizard-content {
        .wizard-nav {
            padding: 0;

            .wizard-steps {
                display: flex;
                align-items: center;

                .wizard-step {
                    padding: .75rem 0;
                    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
                    margin-bottom: 1.5rem;

                    .wizard-wrapper {
                        display: flex;
                        align-items: center;
                    }

                    .wizard-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
                        min-width: 46px;
                        height: 46px;
                        //border-radius: 12px;
                        background-color: #f3f6f9;
                        margin-right: 1rem;

                        .wizard-check {
                            display: none;
                            font-size: 1.4rem;
                        }

                        .wizard-number {
                            font-weight: 600;
                            color: #3f4254;
                            font-size: 1.35rem;
                        }
                    }

                    .wizard-label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .wizard-title {
                            color: #181c32;
                            font-weight: 600;
                            font-size: 1.24rem;
                        }

                        .wizard-desc {
                            color: #b5b5c3;
                            font-size: .925rem;
                        }
                    }
                }

                .wizard-step[data-wizard-state=current] {
                    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;

                    .wizard-icon {
                        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
                        background-color: $wizardBackgroundColor;

                        .wizard-check {
                            color: $wizardTextColor;
                            display: none;
                        }

                        .wizard-number {
                            color: $wizardTextColor;
                        }
                    }

                    .wizard-label {
                        .wizard-title {
                            color: #181c32;
                        }

                        .wizard-desc {
                            color: #b5b5c3;
                        }
                    }

                    &:last-child {
                        .wizard-icon {
                            transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
                            background-color: $wizardBackgroundColor;

                            .wizard-check {
                                color: $wizardTextColor;
                                display: inline-block;
                            }

                            .wizard-number {
                                display: none;
                            }
                        }

                        .wizard-label {
                            .wizard-title {
                                color: #b5b5c3;
                            }

                            .wizard-desc {
                                color: #d1d3e0;
                            }
                        }
                    }
                }

                .wizard-step[data-wizard-state=done] {
                    .wizard-icon {
                        transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
                        background-color: $wizardBackgroundColor;

                        .wizard-check {
                            color: $wizardTextColor;
                            display: inline-block;
                        }

                        .wizard-number {
                            display: none;
                        }
                    }

                    .wizard-label {
                        .wizard-title {
                            color: #b5b5c3;
                        }

                        .wizard-desc {
                            color: #d1d3e0;
                        }
                    }
                }
            }
        }

        form {
            .feedback {
                display: none;
            }

            .form-group label {
                font-weight: 400;
            }

            &.folder-document-form {
                background-color: #f3f6f9;
            }

            .form-check-input.is-valid ~ .form-check-label {
                color: #3F4254;
            }
        }


    }
}

@media (max-width: 767.98px) {
    .wizard.wizard-6 {
        .wizard-content {
            .wizard-nav {
                .wizard-steps {
                    .wizard-step {
                        width: 100%;

                        .wizard-wrapper {
                            .svg-icon {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
