//
// Card
//

// Base
.card.card-custom {

    // Header
    > .card-footer {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        flex-wrap: wrap;
        //height: $card-header-height;
        min-height: $card-header-height;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;


        // Toolbar
        .card-toolbar {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y 0;
            flex-wrap: wrap;
        }

        // Line tabs integration
        &.card-footer-tabs-line {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            align-items: stretch;

            .card-toolbar {
                margin: 0;
            }


        }


    }

    .card-footer {
        .card-toolbar {
            [data-card-tool="toggle"] {
                i {
                    transition: $transition;
                }
            }
        }
    }

    &.card-collapsed,
    &.card-collapse {
        .card-header {
            .card-toolbar {
                [data-card-tool="toggle"] {
                    i {
                        transition: $transition;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
