$asideColor: darken(#0653b6, 15%);
//$asideColor: #4d4d4d;
$asideColorAlt: darken($asideColor, 5%);
$asideTextColor: #FFFFFF;
$asideTitleColor: darken($asideTextColor, 5%);


.brand-dark {
    .brand {
        background-color: $asideColor;

        .btn {
            .svg-icon {
                svg {
                    g {
                        [fill] {
                            fill: $asideTextColor;
                        }
                    }
                }
            }
        }
    }
}

.aside {
    background-color: $asideColor;
}

.aside-menu {
    background-color: $asideColor;

    .menu-nav {
        .menu-section {
            .menu-text {
                color: $asideTitleColor;
            }
        }

        > .menu-item.menu-item-active {
            > .menu-heading {
                background-color: $asideColorAlt;

                .menu-icon {
                    color: $asideTextColor;
                }

                .menu-icon.svg-icon {
                    svg {
                        g {
                            [fill] {
                                fill: $asideTextColor;
                            }
                        }
                    }
                }
            }

            > .menu-link {
                background-color: $asideColorAlt;

                .menu-icon {
                    color: $asideTextColor;
                }

                .menu-icon.svg-icon {
                    svg {
                        g {
                            [fill] {
                                fill: $asideTextColor;
                            }
                        }
                    }
                }
            }
        }

        > .menu-item {
            &:not(.menu-item-parent) {
                &:not(.menu-item-open) {
                    &:not(.menu-item-here) {
                        &:not(.menu-item-active) {
                            &:hover {
                                > .menu-heading {
                                    background-color: $asideColorAlt;

                                    .menu-icon {
                                        color: $asideTextColor;

                                        &.svg-icon {
                                            svg {
                                                g {
                                                    [fill] {
                                                        fill: $asideTextColor;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }

                                > .menu-link {
                                    background-color: $asideColorAlt;

                                    .menu-icon {
                                        color: $asideTextColor;

                                        &.svg-icon {
                                            svg {
                                                g {
                                                    [fill] {
                                                        fill: $asideTextColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > .menu-heading {
                .menu-text {
                    color: $asideTextColor;
                }

                .menu-icon.svg-icon {
                    svg {
                        g {
                            [fill] {
                                fill: $asideTextColor;
                            }
                        }
                    }
                }

                .menu-icon {
                    color: $asideTextColor;
                }
            }

            > .menu-link {
                .menu-text {
                    color: $asideTextColor;
                }

                .menu-icon.svg-icon {
                    svg {
                        g {
                            [fill] {
                                fill: $asideTextColor;
                            }
                        }
                    }
                }

                .menu-icon {
                    color: $asideTextColor;
                }
            }
        }
    }
}


.aside-minimize {
    .aside-menu {
        .menu-nav {
            > .menu-item.menu-item-here {
                > .menu-link {
                    > .menu-icon {
                        @media (min-width: 992px) {
                            color: $asideTextColor;
                        }
                    }
                }
            }

            > .menu-item.menu-item-active {
                > .menu-link {
                    > .menu-icon {
                        @media (min-width: 992px) {
                            color: $asideTextColor;
                        }
                    }
                }
            }
        }
    }
}
