.btn {
    &.btn-font-awesome {
        .svg-icon {
            svg {
                &.ok {
                    path {
                        fill: $success !important;
                    }
                }

                &.ko {
                    path {
                        fill: $danger !important;
                    }
                }
            }
        }
    }

    //.svg-icon.svg-icon-md svg
    &.btn-font-awesome-icon {
        &:hover {
            .svg-icon {
                //@include svg-logo-color($hover-color,true);

                @include svg-logo-color(#FFF, true);
                //svg {
                //    path {
                //        //fill: $color valueif($important, !important, null);
                //        fill:$orange !important;
                //    }
                //}
            }
        }

        .svg-icon {
            &.svg-icon-md {
                .fa-fw {
                    width: 1.5rem;
                }

                svg {
                }
            }

            svg {
                &.ok {
                    path {
                        fill: $success !important;
                    }
                }

                &.ko {
                    path {
                        fill: $danger !important;
                    }
                }
            }
        }
    }

    svg {

    }
}
